export const FETCH_USER_INFO_REQUEST = "FETCH_USER_INFO_REQUEST"
export const FETCH_USER_INFO_SUCCESS = "FETCH_USER_INFO_SUCCESS"
export const FETCH_USER_INFO_FAILED = "FETCH_USER_INFO_FAILED"



export const FETCH_USERBID_INFO_REQUEST = "FETCH_USERBID_INFO_REQUEST"
export const FETCH_USERBID_INFO_SUCCESS = "FETCH_USERBID_INFO_SUCCESS"
export const FETCH_USERBID_INFO_FAILED = "FETCH_USERBID_INFO_FAILED"


export const FETCH_USERSTAT_INFO_REQUEST = "FETCH_USERSTAT_INFO_REQUEST"
export const FETCH_USERSTAT_INFO_SUCCESS = "FETCH_USERSTAT_INFO_SUCCESS"
export const FETCH_USERSTAT_INFO_FAILED = "FETCH_USERSTAT_INFO_FAILED"