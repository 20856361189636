import {
    FILTER_CONSTANT_REQUEST, FILTER_CONSTANT_SUCCESS, FILTER_CONSTANT_FAILED
} from "../../constants/tendergrid/FilterCompanyConstant"

const initialState = {
    companyList: [],
}

export const filterCompanyReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case FILTER_CONSTANT_REQUEST:
            return { ...state }
        case FILTER_CONSTANT_SUCCESS:
            return { ...state, companyList: payload }
        case FILTER_CONSTANT_FAILED:
            return { ...state, error: payload }
        default: return state
    }
}
