import { APIService } from "../../APIService";
import { baseUrl, isAuth } from "../../../utils/configurable";
import { authHeader } from '../../../helper/authHeader'


const deleteTenderFilter = async (data) => {
    const headers = { 'Content-Type': "multipart/form-data" }
    try {
        const response = await APIService.post(`${baseUrl}/delete_tender_filter`, data, { headers })
        return response
    }
    catch (error_msg) {
        return error_msg
    }
}

const getTenderFilters = async (data) => {
    const headers = { 'Content-Type': "multipart/form-data" }
    try {
        const response = await APIService.post(`${baseUrl}/get_tender_filter`, data, { headers });
        return response
    }
    catch (error_msg) {
        return error_msg
    }
}

const setTenderFilters = async (data) => {
    const headers = { 'Content-Type': "multipart/form-data" }
    try {
        const response = await APIService.post(`${baseUrl}/set_tender_filter`, data, { headers });
        return response
    }
    catch (error_msg) {
        return error_msg
    }
}

const deleteAllFilters = async (data) => {
    const headers = { 'Content-Type': "multipart/form-data" }
    try {
        const response = await APIService.post(`${baseUrl}/delete_tender_filter_all`, data, { headers });
        return response
    }
    catch (error_msg) {
        return error_msg
    }
}
export const FilterServices = {
    deleteTenderFilter,
    getTenderFilters,
    setTenderFilters,
    deleteAllFilters
};

