import { ERROR_IREPS_TENDER_GRID, 
    INITIALIZE_IREPS_NETTING_API_FAIL, 
    INITIALIZE_IREPS_NETTING_WITHOUT_AUTH, 
    INITIALIZE_IREPS_NETTING_WITH_AUTH,
     LOAD_IREPS_TENDER_GRID } from "Redux/constants/tendergrid/irepsFilterConstants";



export const irepsInitialState = {
    state_id: "",
    tnd_ref_id: "",
    tnd_id: '',
    tnd_govt_id: '',
    client_id: "",
    tender_keyword: "",
    tender_docs: "",
    sector_id: "",
    country_id: "",
    region_id: "",
    funding_agency_id: "",
    financial_year: '',
    from_date: "",
    to_date: "",
    latest_activity: '',
    published_date: "",
    close_exp_date: "",
    estm_value: "",
    estm_value_emd: "",
    pubdate_cust_from_date: "",
    pubdate_cust_to_date: "",
    expdate_cust_from_date: "",
    expdate_cust_to_date: "",
    amnt_custrange_operator: "",
    amnt_custrange_amount: "",
    custrange_denomination: "",
    amnt_custrange_operator_emd: "",
    amnt_custrange_amount_emd: "",
    custrange_denomination_emd: "",
    sort_key: "",
    sort_val: "",
    limit: 25,
    page_name: '',
    tender_status: 'active',
    no_bid: '',
    tndr_category_type:"",
    wishlist_category_id: '',
    page_number: 0,
    irepsfilterChips: [],
    irepsfilterObject: {},
    tndr_not_specified_amt:"tndr_not_specified_amt",
    tndr_not_specified_emd:"tndr_not_specified_emd",
    error: ''
};

const irepsFilterReducer = (state = irepsInitialState, { type, payload }) => {
    switch (type) {

        case LOAD_IREPS_TENDER_GRID:
            return { ...state, }

        case INITIALIZE_IREPS_NETTING_WITH_AUTH:
            const updatedKeys = Object.keys(payload?.irepsfilterObject);
            const updatedState = {};

            // Loop through the updated keys and copy their values from payload.filterObject
            updatedKeys.forEach((key) => {
                updatedState[key] = payload.irepsfilterObject[key];
            });

            return {
                ...state,
                irepsfilterChips: payload.irepsfilterChips,
                irepsfilterObject: {
                    ...state.irepsfilterObject,
                    ...payload.irepsfilterObject,
                },
                ...updatedState, // Update the initial state with the new key-value pairs
            };
        case INITIALIZE_IREPS_NETTING_WITHOUT_AUTH:

            return { ...state, ...payload.updatedData };
        case INITIALIZE_IREPS_NETTING_API_FAIL:

            return { ...state, ...payload.irepsInitialState };
        case ERROR_IREPS_TENDER_GRID:
            return { ...state, error: payload };
        default:
            return state;
    }
};


export default irepsFilterReducer