import { EPROC_ERROR_TENDER_GRID, EPROC_INITIALIZE_NETTING_API_FAIL, EPROC_INITIALIZE_NETTING_WITHOUT_AUTH, EPROC_INITIALIZE_NETTING_WITH_AUTH } from "Redux/constants/tendergrid/eprocFilterConstant";
import { FilterServices } from "Services/common/filters/FilterServices";

export const eprocNettingAction = (updatedData) => {
    console.log(updatedData,"updatedData");
      return (dispatch) => {
        if (localStorage.getItem('auth')) {
          const formdata = new FormData();
          formdata.append('user_id', localStorage.getItem('user_id'));
          formdata.append('page_name', updatedData?.page_name);
          try {
            FilterServices.getTenderFilters(formdata)
              .then((res) => {
                if (res?.data?.status === 1) {
                  const eprocFilterChips = res?.data?.data; 
                  const eprocFilterObject = {
                    page_number: updatedData?.page_number,
                    limit: updatedData?.limit,
                    page_name: updatedData?.page_name,
                    sort_key: updatedData?.filter_sort_key,
                    sort_val: updatedData?.filter_sort_val,
                    tndr_category_type: updatedData?.filter_tndr_category_type,
                    tndr_not_specified_emd:updatedData?.tndr_not_specified_emd,
                    tndr_not_specified_amt:updatedData?.tndr_not_specified_amt
                  };
    
                  eprocFilterChips.forEach((item) => {
                    if(item.filter_keyword === "tndr_not_specified_emd" || item.filter_keyword  === "tndr_not_specified_amt" ){
                      return 
                    }
                    eprocFilterObject[item.filter_keyword] = item.filter_val;
                  });
                  
                  dispatch({
                    type: EPROC_INITIALIZE_NETTING_WITH_AUTH,
                    payload: { eprocFilterChips, eprocFilterObject },
                  });
                } else if (res?.response?.data?.status === 0) {
                  const eprocInitialState = {
                    state_id: "",
                    tnd_ref_id: "",
                    tnd_id: "",
                    tnd_govt_id: "",
                    client_id: "",
                    tender_keyword: "",
                    tender_docs: "",
                    sector_id: "",
                    country_id: "",
                    region_id: "",
                    funding_agency_id: "",
                    financial_year: '',
                    from_date: "",
                    to_date: "",
                    latest_activity: '',
                    published_date: "",
                    close_exp_date: "",
                    estm_value: "",
                    estm_value_emd: "",
                    pubdate_cust_from_date: "",
                    pubdate_cust_to_date: "",
                    expdate_cust_from_date: "",
                    expdate_cust_to_date: "",
                    amnt_custrange_operator: "",
                    amnt_custrange_amount: "",
                    custrange_denomination: "",
                    amnt_custrange_operator_emd: "",
                    amnt_custrange_amount_emd: "",
                    custrange_denomination_emd: "",
                    sort_key: "",
                    sort_val: "",
                    limit: 25,
                    page_name: updatedData?.page_name,
                    tender_status: 'active',
                    no_bid: '',
                    wishlist_category_id: '',
                    tndr_category_type:"",
                    eprocFilterChips: [],
                    eprocFilterObject: {},
                    page_number: 0,
                    tndr_not_specified_emd:'tndr_not_specified_emd',
                    tndr_not_specified_amt:'tndr_not_specified_amt'
                  };
                  dispatch({
                    type: EPROC_INITIALIZE_NETTING_API_FAIL,
                    payload: { eprocInitialState },
                  });
                } else {
                  console.log('error')
                }
              })
          } catch {
            dispatch({
              type: EPROC_ERROR_TENDER_GRID,
              payload: { error: 'Unable to fetch records at the moment. Please try again in a few minutes.' },
            });
          }
        } else {
          dispatch({
            type: EPROC_INITIALIZE_NETTING_WITHOUT_AUTH,
            payload: { updatedData },
          });
        }
    
    
      };
    };