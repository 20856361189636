import {
    STAT_FILTER_INACTION_FAILED,
    STAT_FILTER_INACTION_REQUEST,
    STAT_FILTER_IS_UPDATE_RESET,
    STAT_FILTER_IS_UPDATE_RESET_TRUE,
    STAT_FILTER_RESET_ARR_KEYS,
    STAT_FILTER_RESET_INDIVIDUAL_ARR_OBJECTS,
    STAT_FILTER_UPDATE_ALL_KEYS_OBJECTS,
    STAT_FILTER_UPDATE_INDIVIDUAL_OBJECTS_KEYS,
    STAT_FILTER_UPDATE_STATE_VAL
} from "../../../Redux/constants/statgrid/statFilterConstant"

let statFilterValObj = {
    limit: 25,
    page: 1,
    client_id: '',
    funding_id: "",
    category_id: "",
    country_id: "",
    state_id: "",
    city_it: "",
    sector_id: ""
}

export const initialState = {
    statFilterValues: statFilterValObj,
    isUpdate: false,
    error: ''
};

const StatFilterReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case STAT_FILTER_INACTION_REQUEST:
            return { ...state }

        case STAT_FILTER_UPDATE_ALL_KEYS_OBJECTS:
            // debugger;
            const updatedStatFilterValues = { ...payload };
            return { ...state, statFilterValues: updatedStatFilterValues, isUpdate: true };

        case STAT_FILTER_UPDATE_INDIVIDUAL_OBJECTS_KEYS:
            const updatedStatFilterValuesIndividual = { ...state.statFilterValues, ...payload };

            return { ...state, statFilterValues: updatedStatFilterValuesIndividual, isUpdate: true };

            case STAT_FILTER_RESET_ARR_KEYS:
                return { ...state, statFilterValues: statFilterValObj, isUpdate: true };
    
            case STAT_FILTER_IS_UPDATE_RESET:
                return { ...state, isUpdate: false };
    
            case STAT_FILTER_IS_UPDATE_RESET_TRUE:
                return { ...state, isUpdate: true };
    
            case STAT_FILTER_INACTION_FAILED:
                return { ...state, error: payload, isUpdate: false };
    

        default:
            return state;
    }


}


export default StatFilterReducer;