// @ts-nocheck
import ROUTES from "Constants/Routes"
import { LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILED, LOGOUT, LOGOUTSTATGRID_DATA_REQUESTCCESS, LOGOUT_BID, BIDGRID_DATA_REQUEST, BIDGRID_DATA_SUCCESS, BIDGRID_DATA_FAILED, STATGRID_DATA_REQUEST, STATGRID_DATA_SUCCESS, STATGRID_DATA_FAILED, LOGOUT_STAT } from "../../constants/common/authConstant"
import { userBidInfoAction, userInfoAction, userStatInfoAction } from "./userInfoAction"
import { dropdownMenuResetAction, bidDropdownActionReset, dropdownValResetAction } from "./DropdownAction"
import { initialState } from "Redux/reducers/tendergrid/advanceFiltersReducer"
import { geminitialState } from "Redux/reducers/tendergrid/gemFilterReducer"
import { getNettingAction } from "../tendergrid/getNettingAction"
import { getGemNettingAction } from "../tendergrid/getGemNettingAction"
import { irepsInitialState } from "Redux/reducers/tendergrid/irepsFilterReducer"
import { irepsNettingAction } from "../tendergrid/irepsNettingAction"
import { eprocNettingAction } from "../tendergrid/eprocNettingAction"
import { eprocInitialState } from "Redux/reducers/tendergrid/eprocFilterReducer"
import { NEW_APP_LAUNCH } from "../../constants/common/authConstant"


export const userLoginAction = (data) => async (dispatch) => {

    try {
        dispatch({ type: LOGIN_REQUEST })
        dispatch({ type: LOGIN_SUCCESS, payload: data })
    }
    catch (error) {
        dispatch({ type: LOGIN_FAILED, payload: error })
    }
}

export const bidGridAction = (data) => async (dispatch) => {

    try {
        dispatch({ type: BIDGRID_DATA_REQUEST })
        dispatch({ type: BIDGRID_DATA_SUCCESS, payload: data })
    }
    catch (error) {
        dispatch({ type: BIDGRID_DATA_FAILED, payload: error })
    }
}


export const statGridAction = (data) => async (dispatch) => {

    try {
        dispatch({ type: STATGRID_DATA_REQUEST })
        dispatch({ type: STATGRID_DATA_SUCCESS, payload: data })
    }
    catch (error) {
        dispatch({ type: STATGRID_DATA_FAILED, payload: error })
    }
}


export const userLogoutAction = () => {

    return async (dispatch) => {


        try {
            // window.history.pushState({}, "", ROUTES.LOGIN);

            localStorage.setItem("auth", "");
            localStorage.setItem("user_id", "");
            localStorage?.removeItem("eproc_undefined")
            localStorage?.removeItem("gemState_undefined")
            localStorage?.removeItem("ireps_undefined")
            await dispatch(userInfoAction({}));
            await dispatch(userLoginAction({}));
            await dispatch(dropdownMenuResetAction({}));
            await dispatch(dropdownValResetAction({}));
            await dispatch(getNettingAction(initialState))
            await dispatch(getGemNettingAction(geminitialState))
            await dispatch(irepsNettingAction(irepsInitialState))
            await dispatch(eprocNettingAction(eprocInitialState))


            await dispatch({ type: LOGOUT });


        } catch (error) {
            console.log(error, "Error purging persistor");
        }
    }
}

export const appVersionChangeAction = () => {
    return async (dispatch) => {
        try {
            localStorage.clear();
            await dispatch({ type: NEW_APP_LAUNCH });
        } catch (error) {
            console.log(error, "Error purging persistor");
        }
    }
}

export const userBidLogoutAction = () => {

    return async (dispatch, getState) => {
        const state = getState();
        const socketInstance = state.socket.socket;
        if (socketInstance) {
            socketInstance.disconnect();
            dispatch({
                type: 'SET_SOCKET',
                payload: { socket: socketInstance, isConnected: socketInstance?.connected },
            });
        }
        try {
            await dispatch(userBidInfoAction({}))
            await dispatch(bidGridAction({}))
            // await dispatch(bidDropdownActionReset())
            dispatch({ type: LOGOUT_BID });
            window.history.pushState({}, "", ROUTES.BD_LOGIN);
            localStorage.setItem("bidToken", '');
        } catch (error) {
            console.log(error, "Error purging persistor");
        }
    }
}


export const userStatLogoutAction = () => {

    return async (dispatch) => {
        try {
            localStorage.removeItem("statToken");
            await dispatch(userStatInfoAction({}))
            await dispatch(statGridAction({}))
            // await dispatch(bidDropdownActionReset())
            await dispatch({ type: LOGOUT_STAT });
            window.history.pushState({}, "", ROUTES?.STAT_LOGIN);
            // window.location.reload();


        } catch (error) {
            console.log(error, "Error purging persistor");
        }
    }
}


// export const userLoginSuccess = (userData,userInfo) => ({
//     type: LOGIN_SUCCESS,
//     payload: {userData,userInfo}
//   });