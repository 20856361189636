import ROUTES from "Constants/Routes";
import { persistStore } from 'redux-persist';
import { persistor } from '../../store'
import {
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_FAILED,
    LOGOUT,

    BIDGRID_DATA_REQUEST,
    BIDGRID_DATA_SUCCESS,
    BIDGRID_DATA_FAILED,
    LOGOUT_BID,

    STATGRID_DATA_REQUEST,
    STATGRID_DATA_SUCCESS,
    STATGRID_DATA_FAILED,
    LOGOUT_STAT

} from "../../constants/common/authConstant";
import { NEW_APP_LAUNCH } from "../../constants/common/authConstant";
const initialState = {
    userData: {},
    bidgridData: {},
    statgridData: {}
};

//     return async (dispatch) => {
//         try {
//             await persistor.purge(); // Wait for the persistence to finish
//             dispatch({ type: LOGOUT });
//             window.history.pushState({}, "", ROUTES.LOGIN);
//             localStorage.clear();
//             localStorage.setItem("auth", "");
//             window.location.reload();
//         } catch (error) {
//             console.error("Error purging persistor:", error);
//         }
//     };
// };


// const logout = () => {
//     window.history.pushState({}, "", ROUTES.LOGIN);
//     localStorage.clear();
//     window.location.reload();
//     localStorage.setItem("auth", "");
// };

export const userDataReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case LOGIN_REQUEST:
            return { ...state };
        case LOGIN_SUCCESS:
            return { ...state, userData: payload };
        case LOGIN_FAILED:
            return { ...state, error: payload };
        case LOGOUT:
            return { ...state };


        case BIDGRID_DATA_REQUEST:
            return { ...state };
        case BIDGRID_DATA_SUCCESS:
            return { ...state, bidgridData: payload };
        case BIDGRID_DATA_FAILED:
            return { ...state, error: payload };
        case LOGOUT_BID:
            return { ...state };

        case STATGRID_DATA_REQUEST:
            return { ...state };
        case STATGRID_DATA_SUCCESS:
            return { ...state, statgridData: payload };
        case STATGRID_DATA_FAILED:
            return { ...state, error: payload };
        case LOGOUT_STAT:
            return { ...state };
        case NEW_APP_LAUNCH:
            return { ...initialState }

        default:
            return state;
    }
};
