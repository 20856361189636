// @ts-nocheck
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { UserInfoServices } from "../Services/common/user/userinfo";
import { userInfoAction } from '../Redux/actions/common/userInfoAction';
import { CountService } from "../Services/tendergrid/tendercount/tendercount";
import { DropdownValuesServices } from '../Services/common/dropdown/dropdownValues'
import { useDispatch } from "react-redux";
import { dropdownValAction, dynamicMenuAction, seoValuesAction } from '../Redux/actions/common/DropdownAction';
import { NavBarMenu } from "Services/common/navbar/NavBarMenu";
import { useLocation, useNavigate } from "react-router-dom";
const initialState = {
    country: [],
    state: [],
    sector: [],
    client: [],
    fundingAgency: [],
    fin_year: [],
    tndr_category_type: [],
    clientByState: [],
    webSiteData: [],
    irepsClient: []
}
const DropdownValues = () => {
    const [data, setData] = useState(initialState)
    const objDetiail = new FormData()
    const location = useLocation()
    const val = location?.pathname;
    const str = val.replace('/', '')
    const dispatch = useDispatch()
    const { dynamicMenu } = useSelector((state) => state.dynamicMenuVal)
    const { seoValue } = useSelector((state) => state.seoValues)
    const { userData } = useSelector((state) => state.loginData)
    const { dropdownValues } = useSelector((state) => state.dropdownCalVal)
    const navigate = useNavigate()
    const nettingData = useSelector((state) => state.nettingData)


    useEffect(() => {
        let url = location.pathname;
        if (url.endsWith('/')) {
            url = url.replace(/\/+/g, '');
            navigate(url)
        }
    }, [location]);

    const userServiceRes = () => {

        objDetiail.append('user_id', userData?.user_data?.loginid)
        try {
            UserInfoServices.requestUser(objDetiail)
                .then((response) => {
                    if (response?.data?.status === 1) {
                        dispatch(userInfoAction(response.data.data))
                    }
                    else if (response?.validation_error) {
                        localStorage.clear();
                        window.location.reload();
                        localStorage.setItem("auth", "");
                    }
                })
        }
        catch {
            console.log('error')
        }
    }


    const getNavBarMenuItem = () => {
        try {
            NavBarMenu?.getNavBar().then((response) => {
                console.log(response, "responsedrop");
                let data = Object?.values(response?.data?.data)
                dispatch(dynamicMenuAction(data))

            });
        } catch (error) {
            console.log(error);
        }
    };



    const getCountry = async () => {
        try {
            await DropdownValuesServices.getCountry().then(res => {
                setData(prevState => ({ ...prevState, country: res?.data?.countries?.filter(val => val?.country_name !== 'NA') }))
            })
        } catch (error) {
            console.log(error)
        }
    }

    const getStates = async () => {
        objDetiail.append('country_id', 1);
        try {
            const res_state = await DropdownValuesServices.getStateDataByCountryId(objDetiail);
            console.log(res_state, "res_state");
            const res_state_tender_count = await CountService.getStateCount();

            if (res_state?.data?.status === 1 && res_state_tender_count?.data?.status === 1) {
                const stateCount = res_state_tender_count?.data?.data;
                // console.log(stateCount, "stateCount");

                const updateStateData = res_state?.data?.data.map(state => ({
                    ...state,
                    tender_count: parseInt(stateCount[state?.state_id]) || 0
                }));
                // console.log(updateStateData, "updateStateData");

                setData(prevState => ({
                    ...prevState,
                    state: updateStateData.filter(val => val?.state_name !== 'NA')
                }));
            }
        } catch (error) {
            console.log(error);
        }
    };

    const getClient = async () => {
        try {
            const res_client = await DropdownValuesServices.getClientList();
            const tender_count = await CountService.getClientCount();
            if (res_client?.data?.status === 1 && tender_count?.data?.status === 1) {
                const clientCount = tender_count?.data?.data;
                const updatedClientData = res_client?.data?.data.map(client => ({
                    ...client,
                    tender_count: parseInt(clientCount[client.fld_id]) || 0
                }));

                setData(prevState => ({
                    ...prevState,
                    client: updatedClientData?.filter(val => val?.client_name !== 'NA')
                }));
            }
        } catch (error) {
            console.log(error);
        }
    };

    const getClientByState = async (val) => {
        try {
            const PayloadData = new FormData();
            PayloadData.append('state_id', val || "");
            const res_client = await DropdownValuesServices.getclientListState(PayloadData);
            const tender_count = await CountService.getClientCount();

            if (res_client?.data?.status === 1 && tender_count?.data?.status === 1) {
                const clientCount = tender_count?.data?.data;
                const updatedClientData = res_client?.data?.data.map(clientByState => ({
                    ...clientByState,
                    tender_count: parseInt(clientCount[clientByState.fld_id]) || 0
                }));

                setData(prevState => ({
                    ...prevState,
                    clientByState: updatedClientData?.filter(val => val?.client_name !== 'NA')
                }));
            }
        } catch (error) {
            console.log(error);
        }
    };
    const getSector = async () => {
        try {
            const res_sector = await DropdownValuesServices.getTenderCategory();
            const tender_count_sector = await CountService.getSectorCount();

            if (res_sector?.data?.status === 1 && tender_count_sector?.data?.status === 1) {

                const sectorCount = tender_count_sector?.data?.data;
                const updateSectorData = res_sector?.data?.data.map(sector => ({
                    ...sector,
                    tender_count_sector: parseInt(sectorCount[sector.fld_id]) || 0
                }));

                setData(prevState => ({
                    ...prevState,
                    sector: updateSectorData?.filter(val => val?.sectName !== 'NA')
                }));
            }
        } catch (error) {
            console.log(error);
        }
    };

    const getWebsite = async () => {
        const currentYear = new Date().getFullYear();
        const previousYear = currentYear - 1
        const finYearString = `${previousYear}-${currentYear}`;
        const data = {
            fin_year: finYearString,
        };
        const payload = new FormData();
        payload.append('fin_year', data?.fin_year);
        try {
            const web_res = await DropdownValuesServices?.getWebsiteList();
            const web_tender_count = await CountService.getwebsitecount(payload);

            if (web_res?.data?.status === 1 && web_tender_count?.data?.status === 1) {

                const webCount = web_tender_count?.data?.data;
                const updateWebData = web_res?.data?.data.map(webSiteData => ({
                    ...webSiteData,
                    web_tender_count: parseInt(webCount[webSiteData.fld_id]) || 0
                }));

                setData(prevState => ({
                    ...prevState,
                    webSiteData: updateWebData?.filter(val => val?.url !== 'NA')
                }));
            }
        } catch (error) {
            console.log(error);
        }
    };



    const getAgency = () => {
        try {
            DropdownValuesServices.getFundingList().then(res => {
                if (res?.data?.status === 1) {
                    setData(prevState => ({ ...prevState, fundingAgency: res?.data?.data.filter(val => val?.funding_org !== 'NA') }))
                }
            })
        } catch (error) {
            console.log(error)
        }
    }

    const getFinYear = () => {
        try {
            DropdownValuesServices.getFinancialYear().then(res => {
                if (res?.data?.status === 1) {
                    setData(prevState => ({ ...prevState, fin_year: res?.data?.data.filter(val => val?.slot_name !== 'NA') }))
                }
            })
        } catch (error) {
            console.log(error)
        }
    }

    const getTendercategoryType = () => {
        try {
            DropdownValuesServices.getTenderType().then(res => {
                if (res?.data?.status === 1) {
                    setData(prevState => ({ ...prevState, tndr_category_type: res?.data?.data.filter(val => val?.category_phase !== 'NA') }))
                }
            })
        } catch (error) {
            console.log(error)
        }
    }


    const getIrepsAuthority = () => {
        try {
            DropdownValuesServices.getIrepsDataList().then(res => {
                if (res?.data?.status === 1) {
                    setData(prevState => ({ ...prevState, irepsClient: res?.data?.data.filter(val => val?.client_name !== 'NA') }))
                }
            })
        } catch (error) {
            console.log(error)
        }
    }


    // const setFirebaseNotification = () => {
    //     const formData = new FormData()
    //     formData.append('user_id', userData?.user_data?.loginid)
    //     formData.append('token', fireBase_token)
    //     if (fireBase_token) {
    //         try {
    //             firebaseServices.webPushNotification(formData).then(res => {
    //                 console.log("firebase response", res)
    //             })
    //         } catch (error) {
    //             console.log(error)
    //         }
    //     }
    // }
    const dispatchDropdownValues = () => {
        if (data?.sector?.length !== 0 && data?.country?.length !== 0 && data?.client?.length !== 0 && data?.state?.length !== 0 && data?.fin_year?.length !== 0 && data?.fundingAgency?.length !== 0 && data?.irepsClient?.length !== 0) {
            dispatch(dropdownValAction(data))
        }
    }

    const windowReloadORdirectAccess = () => {
        const pathname = location.pathname;
        const parts = pathname.split('/').filter(part => part !== '');
        let tender_id = parts[parts?.length - 1]
        let fin_year = nettingData?.financial_year ? nettingData?.financial_year : localStorage.getItem('fin_year');
        let updatedstr;
        if (parts?.length <= 2) {
            updatedstr = parts.join('/');
        } else {
            updatedstr = parts.slice(0, 2).join('/')
        }

        let type = parts[2]
        let type_name = decodeURIComponent(parts[3])
        let matchedItem = dynamicMenu?.find(item => item.action_url === updatedstr);
        if (!matchedItem) {
            dynamicMenu?.forEach(item => {
                if (item.child) {
                    const childMatch = item.child.find(childItem => childItem.action_url === updatedstr);
                    if (childMatch) {
                        matchedItem = childMatch;
                        return;
                    }
                }
            });
        }
        const id = matchedItem ? matchedItem.fld_id : null;
        if (id && tender_id && fin_year) {
            console.log("hello");
            dispatch(seoValuesAction(id, type, type_name, dropdownValues, tender_id, fin_year))
        }
    }
    useEffect(() => {
        // Function to handle URL changes
        const handleUrlChange = () => {
            var currentUrl = window.location.href;
            if (currentUrl.includes("tendergrid")) {
                document.body.className = "tender-grid-module";
            } else if (currentUrl.includes("bidgrid")) {
                if (str === "bidgrid/chatboat") {
                    document.body.className = "bid-grid-module bd_chat_module";
                } else {
                    document.body.className = "bid-grid-module";
                }

            } else {
                document.body.className = "tender-grid-module";
            }
        };

        // Initial setup
        handleUrlChange();

    }, [location.pathname]);

    useEffect(() => {
        windowReloadORdirectAccess()
    }, [location.pathname])

    useEffect(() => {
        document.title = seoValue?.seo_val ? seoValue?.seo_val + " " + "Tenders" : seoValue?.page_title;

        const metaTag = document.querySelector('meta[name="description"]');
        if (metaTag) {
            metaTag.setAttribute('content', seoValue?.seo_val ? seoValue?.seo_val + " " + "Tenders" + " " + seoValue?.meta_desc : seoValue?.meta_desc);
        }
        const metaTagKey = document.querySelector('meta[name="keywords"]');
        if (metaTagKey) {
            metaTagKey.setAttribute('content', seoValue?.seo_val ? seoValue?.seo_val + " " + "Tenders" + " " + seoValue?.meta_key : seoValue?.meta_key);
        }
    }, [seoValue]);


    useEffect(() => {
        if (window.location.href.includes("bidgrid")) {
            return
        } else {
            if (dynamicMenu?.length > 0) {
                return
            } else {
                if (localStorage.getItem('auth') !== '') {
                    userServiceRes()
                }
                getStates()
                getCountry()
                getSector()
                getClient()
                getAgency()
                getFinYear()
                getNavBarMenuItem()
                getTendercategoryType()
                getClientByState()
                getWebsite()
                getIrepsAuthority()
                // setFirebaseNotification()
            }
        }

    }, [userData, dynamicMenu])

    useEffect(() => {
        dispatchDropdownValues()
    }, [data])

    // useEffect(() => {
    //     const fetchIpAddress = async () => {
    //         try {
    //             const response = await fetch('https://api.ipify.org?format=json');
    //             const data = await response.json();
    //             localStorage.setItem("ipAddress", data.ip);
    //         } catch (error) {
    //             console.error('Error:', error);
    //         }
    //     };

    //     fetchIpAddress();
    // }, [])

    return;
}

export default DropdownValues



