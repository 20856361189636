import { APIService } from "../../APIService";
import { baseUrl, isAuth } from "../../../utils/configurable";
import { authHeader } from '../../../helper/authHeader'


const stateWiseTenderCount = async (data) => {

    try {
        const response = await APIService.post(`${baseUrl}/tender_count_state_wise`, data);
        return response.data
    }
    catch (error_msg) {
        return error_msg.response
    }
}

const tenderCount = async (data) => {

    try {
        const response = await APIService.post(`${baseUrl}/count_action_tender`, data);
        return response.data
    }
    catch (error_msg) {
        return error_msg.response
    }
}


const getClientCount = async (data) => {
    const headers = { 'Content-Type': "multipart/form-data" }
    try {
        const response = await APIService.post(`${baseUrl}/tender_count_client_wise`, data, { headers })
        return response
    }
    catch (error_msg) {
        return error_msg
    }
}

const getStateCount = async (data) => {
    const headers = { 'Content-Type': "multipart/form-data" }
    try {
        const response = await APIService.post(`${baseUrl}/tender_count_state_wise`, data, { headers })
        return response
    }
    catch (error_msg) {
        return error_msg
    }
}

const getSectorCount = async (data) => {
    const headers = { 'Content-Type': "multipart/form-data" }
    try {
        const response = await APIService.post(`${baseUrl}/tender_count_sector_wise`, data, { headers })
        return response
    }
    catch (error_msg) {
        return error_msg
    }
}

const allMyViewList = async (data) => {
    const headers = authHeader()
    try {
        const response = await APIService.post(`${baseUrl}/get_tndr_view_byuser`, data, { headers })
        return response
    }
    catch (error_msg) {
        return error_msg
    }
}


const setTenderClickCount = async (data) => {

    try {
        const response = await APIService.post(`${baseUrl}/set_tender_click_count`, data)
        return response
    }
    catch (error_msg) {
        return error_msg
    }
}

const setTenderView = async (data) => {
    const headers = authHeader()
    try {
        const response = await APIService.post(`${baseUrl}/tndr_view_byuser`, data, { headers })
        return response
    }
    catch (error_msg) {
        return error_msg
    }
}

const getTenderClickCount = async (data) => {

    try {
        const response = await APIService.post(`${baseUrl}/get_tender_click_count`, data)
        return response
    }
    catch (error_msg) {
        return error_msg
    }
}

// const getwebsitecount = async (data) => {

//     try {
//         const response = await APIService.post(`${baseUrl}/website_count_fin_year`, data)
//         return response
//     }
//     catch (error_msg) {
//         return error_msg
//     }
// }


const getwebsitecount = async (data) => {
    const headers = { 'Content-Type': "multipart/form-data" }

    try {
        const response = await APIService.post(`${baseUrl}/website_count_fin_year`, data, { headers });
        return response;
    } catch (error) {
        return error;
    }
};

const getwebsiteNameTender = async (data) => {
    const headers = { 'Content-Type': "multipart/form-data" }

    try {
        const response = await APIService.post(`${baseUrl}/count_tender_website_wise`, data, { headers });
        return response;
    } catch (error) {
        return error;
    }
};

const getWebsiteCount = async (data) => {
    const headers = { 'Content-Type': "multipart/form-data" }

    try {
        const response = await APIService.post(`${baseUrl}/latest_tenders_count_fin_year`, data, { headers });
        return response;
    } catch (error) {
        return error;
    }
};

export const CountService = {
    tenderCount,
    stateWiseTenderCount,
    getClientCount,
    getStateCount,
    getSectorCount,
    allMyViewList,
    setTenderView,
    getTenderClickCount,
    setTenderClickCount,
    getwebsitecount,
    getwebsiteNameTender,
    getWebsiteCount

}