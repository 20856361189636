// @ts-nocheck
import { DROPDOWN_REQUEST, BID_DROPDOWN_RESETALL, DROPDOWN_FAILED, DROPDOWN_RESETALL, DROPDOWN_SUCCESS, SEO_REQUEST, SEO_SUCCESS, SEO_FAILED, DYNAMIC_MENU_REQUEST, DYNAMIC_MENU_SUCCESS, DYNAMIC_MENU_FAILED, BID_DROPDOWN_REQUEST, BID_CLIENT_SUCCESS, BID_DROPDOWN_FAILED, BID_CLIENT_FUNDING_AGENCY_SUCCESS, DROPDOWN_MENU_RESET, BID_COUNTRY_SUCCESS, BID_SECTOR_SUCCESS, STAT_CLIENT_SUCCESS, STAT_FUNDING_SUCCESS, STAT_TENDER_CAT_SUCCESS, STAT_DROPDOWN_FAILED, STAT_DROPDOWN_REQUEST, STAT_DROPDOWN_RESETALL, STAT_TENDER_COUNTRY_SUCCESS, STAT_TENDER_STATE_SUCCESS, STAT_SECTOR_SUCCESS } from "../../constants/common/DropdownConstant"
import { NavBarMenu } from '../../../Services/common/navbar/NavBarMenu'

export const dropdownValAction = (data) => async (dispatch) => {

    try {
        dispatch({ type: DROPDOWN_REQUEST })
        dispatch({ type: DROPDOWN_SUCCESS, payload: data })
    }
    catch (error) {
        dispatch({ type: DROPDOWN_FAILED, payload: error })
    }
}

export const dynamicMenuAction = (data) => async (dispatch) => {
    console.log(data, "data");

    try {
        dispatch({ type: DYNAMIC_MENU_REQUEST })
        dispatch({ type: DYNAMIC_MENU_SUCCESS, payload: data })
    }
    catch (error) {
        dispatch({ type: DYNAMIC_MENU_FAILED, payload: error })
    }
}

export const dropdownValResetAction = () => async (dispatch) => {

    dispatch({ type: DROPDOWN_RESETALL })

}
export const dropdownMenuResetAction = () => async (dispatch) => {

    dispatch({ type: DROPDOWN_MENU_RESET })

}
export const bidClientAction = (data) => async (dispatch) => {

    try {
        dispatch({ type: BID_DROPDOWN_REQUEST })
        dispatch({ type: BID_CLIENT_SUCCESS, payload: data })
    }
    catch (error) {
        dispatch({ type: BID_DROPDOWN_FAILED, payload: error })
    }
}


export const bidFundingClientAction = (data) => async (dispatch) => {

    try {
        dispatch({ type: BID_DROPDOWN_REQUEST })
        dispatch({ type: BID_CLIENT_FUNDING_AGENCY_SUCCESS, payload: data })
    }
    catch (error) {
        dispatch({ type: BID_DROPDOWN_FAILED, payload: error })
    }
}

export const bidCountryAction = (data) => async (dispatch) => {

    try {
        dispatch({ type: BID_DROPDOWN_REQUEST })
        dispatch({ type: BID_COUNTRY_SUCCESS, payload: data })
    }
    catch (error) {
        dispatch({ type: BID_DROPDOWN_FAILED, payload: error })
    }
}


export const bidSectorAction = (data) => async (dispatch) => {

    try {
        dispatch({ type: BID_DROPDOWN_REQUEST })
        dispatch({ type: BID_SECTOR_SUCCESS, payload: data })
    }
    catch (error) {
        dispatch({ type: BID_DROPDOWN_FAILED, payload: error })
    }
}



export const bidDropdownActionReset = () => async (dispatch) => {

    dispatch({ type: BID_DROPDOWN_RESETALL })

}


export const StatClientAction = (data) => async (dispatch) => {

    try {
        dispatch({ type: STAT_DROPDOWN_REQUEST })
        dispatch({ type: STAT_CLIENT_SUCCESS, payload: data })
    }
    catch (error) {
        dispatch({ type: STAT_DROPDOWN_FAILED, payload: error })
    }
}


export const StatFundingAction = (data) => async (dispatch) => {

    try {
        dispatch({ type: STAT_DROPDOWN_REQUEST })
        dispatch({ type: STAT_FUNDING_SUCCESS, payload: data })
    }
    catch (error) {
        dispatch({ type: STAT_DROPDOWN_FAILED, payload: error })
    }
}

export const StatTenderCatTypeAction = (data) => async (dispatch) => {

    try {
        dispatch({ type: STAT_DROPDOWN_REQUEST })
        dispatch({ type: STAT_TENDER_CAT_SUCCESS, payload: data })
    }
    catch (error) {
        dispatch({ type: STAT_DROPDOWN_FAILED, payload: error })
    }
}

export const StatCountryTypeAction = (data) => async (dispatch) => {

    try {
        dispatch({ type: STAT_DROPDOWN_REQUEST })
        dispatch({ type: STAT_TENDER_COUNTRY_SUCCESS, payload: data })
    }
    catch (error) {
        dispatch({ type: STAT_DROPDOWN_FAILED, payload: error })
    }
}


export const StatStateTypeAction = (data) => async (dispatch) => {

    try {
        dispatch({ type: STAT_DROPDOWN_REQUEST })
        dispatch({ type: STAT_TENDER_STATE_SUCCESS, payload: data })
    }
    catch (error) {
        dispatch({ type: STAT_DROPDOWN_FAILED, payload: error })
    }
}

export const StatSectorAction = (data) => async (dispatch) => {

    try {
        dispatch({ type: STAT_DROPDOWN_REQUEST })
        dispatch({ type: STAT_SECTOR_SUCCESS, payload: data })
    }
    catch (error) {
        dispatch({ type: STAT_DROPDOWN_FAILED, payload: error })
    }
}
export const statDropdownActionReset = () => async (dispatch) => {

    dispatch({ type: STAT_DROPDOWN_RESETALL })

}
const convertToEncoded = (str) => {
    // console.log(str,"str");
    // let updatedName = str.toLowerCase().replace(/\s+/g, ' ').split(' ').join('-').split(',').join('-').split('.').join('').split('(').join('').split(')').join('');
    // let updatedName = str.toLowerCase().replace(/\s+/g, ' ')
    //     .replace(/[^a-zA-Z0-9\s&,.()]+/g, '')
    //     .split(' ')
    //     .map(word => word.replace(/[^a-zA-Z0-9]+/g, ''))
    //     .filter(word => word.length > 0)
    //     .join('-').split(' ').join('-').split('&').join('and').split(',').join('-').split('.').join('').split('(').join('').split(')').join('').split(" - ").join(" ");
    // updatedName += '-tenders';
    // return updatedName

    let updatedName = str?.toLowerCase()
        .replace(/\s+/g, '-')
        .replace(/[^a-zA-Z0-9\s,.()-]/g, '') // Remove special characters except space, &, , , (, and )
        .replace(/(-and-|-of-|-in-)/ig, '-$1') // Correct hyphens around certain words
        .replace(/(^-+|-+$)/g, '')
        .replace(/\./g, '-')
        .replace(/\(/g, '-')    // .replace(/%2C$/, '-')
        .split("(").join("").split(')').join("").split("&").join("").split(",").join("-");
    updatedName = `${updatedName?.replace(/-+/g, '-')}-tenders`;
    return updatedName
}
const convertTosectorEncode = (str) => {
    let updatedName = str.toLowerCase().split(' ').join('-').split('&').join('and').split('(').join('').split(')').join('');
    return updatedName
}
const convertToStateEncoded = (str) => {
    let updatedName = str.toLowerCase().split(' ').join('-').split('&').join('and').split('(').join('').split(')').join('');
    updatedName += '-tenders';
    return updatedName
}

export const seoValuesAction = (id, type, type_name, data, tender_id, fin_year) => {
    console.log(id, "id");
    console.log(tender_id, "seoI");
    // console.log(data?.client,"name of client")

    return (dispatch) => {
        dispatch({ type: SEO_REQUEST });
        const formdata = new FormData();
        const formData = new FormData()
        formdata.append('page_id', id)
        formdata.append("tender_id", tender_id ? tender_id : "")
        formdata.append("fin_year", fin_year)
        formData.append("selct_type", type)
        let type_id

        if (type == "sector") {
            type_id = data?.sector?.find(val => convertTosectorEncode(val.sectName) == type_name)
            // console.log( "sector",data?.sector?.find(val => convertTosectorEncode(val.sectName) === type_name));
            formData.append("select_id", parseInt(type_id?.fld_id))
            // formdata.append("sector_id", parseInt(type_id?.fld_id))
        } else if (type == "authority") {
            type_id = data?.client?.find(val => convertToEncoded(val.client_name) == type_name)
            // formdata.append("client_id", parseInt(type_id?.fld_id))
            formData.append("select_id", parseInt(type_id?.fld_id))
        } else if (type == "state") {
            type_id = data?.state?.find(val => convertToStateEncoded(val.state_name) == type_name)
            // formdata.append("state_id", parseInt(type_id?.state_id))
            // formData.append("state_id", parseInt(type_id?.state_id))
            formData.append("select_id", parseInt(type_id?.state_id))

        }

        if (type && type_id) {
            NavBarMenu?.getAdvanceSeo(formData).then((res) => {
                if (res?.data?.status === 1) {
                    dispatch({
                        type: SEO_SUCCESS,
                        payload: res?.data?.data,
                    });
                } else {
                    dispatch({
                        type: SEO_FAILED,
                        payload: 'Error message',
                    });
                }
            })
        } else {
            if (id && tender_id && fin_year) {
                NavBarMenu.getSeoRequest(formdata)
                    .then((res) => {
                        if (res?.data?.status === 1) {
                            dispatch({
                                type: SEO_SUCCESS,
                                payload: res?.data?.data,
                            });
                        } else {
                            dispatch({
                                type: SEO_FAILED,
                                payload: 'Error message',
                            });
                        }
                    })
            }

        }


    };
};
