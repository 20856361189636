// @ts-nocheck
import {
    TRASH_FILTER_INACTION_REQUEST,
    TRASH_FILTER_UPDATE_INDIVIDUAL_OBJECTS_KEYS,
    TRASH_FILTER_INACTION_FAILED,
    // MIS_FILTER_INACTION_REQUEST,
    TRASH_FILTER_UPDATE_ALL_KEYS_OBJECTS,
    // MIS_FILTER_UPDATE_INDIVIDUAL_OBJECTS_KEYS,
    TRASH_FILTER_RESET_ARR_KEYS,
    TRASH_FILTER_RESET_INDIVIDUAL_ARR_OBJECTS,
    TRASH_FILTER_IS_UPDATE_RESET,
} from "../../constants/bidgrid/bidgridConstants";
import { NEW_APP_LAUNCH } from "Redux/constants/common/authConstant";
let trashFilterValObj = {
    limit: '',
    page_number: '',
    country_id: '',
    state_id: '',
    sector_id: '',
    tender_keyword: '',
    from_date: '',
    to_date: '',
    client_id: '',
    currency_id: '',
    funding_id: '',
    tender_status: '',
    published_date: '',
    close_exp_date: '',
    estm_value: '',
    estm_value_emd: '',
    pubdate_cust_from_date: '',
    pubdate_cust_to_date: '',
    expdate_cust_from_date: '',
    expdate_cust_to_date: '',
    amnt_custrange_operator: '',
    amnt_custrange_amount: '',
    custrange_denomination: '',
    amnt_custrange_operator_emd: '',
    amnt_custrange_amount_emd: '',
    custrange_denomination_emd: '',
    cycle_id: '',
    // tender_result_id: '',
    // to_cycle_id: '',
    // orderSerial: '8'
}

const convertObjectToArr = (val) => {
    const chipsArr = Object.entries(val).map(([key, value]) => ({ key, value }));
    return chipsArr;
}

export const initialState = {
    trashFilterValues: trashFilterValObj,
    trashFilterChips: convertObjectToArr(trashFilterValObj),
    // stateValue: [],
    isUpdate: false,
    error: ''
};

const TrashFilterReducer = (state = initialState, { type, payload }) => {
    // debugger;
    switch (type) {

        case TRASH_FILTER_INACTION_REQUEST:
            return { ...state };

        case TRASH_FILTER_UPDATE_ALL_KEYS_OBJECTS:
            // debugger;
            const updatedtrashFilterValues = { ...payload };
            return { ...state, trashFilterValues: updatedtrashFilterValues, trashFilterChips: convertObjectToArr(updatedtrashFilterValues), isUpdate: true };

        // CAN BE USED AS APPLY TEMPLATE TOO
        case TRASH_FILTER_UPDATE_INDIVIDUAL_OBJECTS_KEYS:
            const updatedtrashFilterValuesIndividual = { ...state.trashFilterValues, ...payload };
            const updatedFilterChipsIndividual = state.trashFilterChips.map(chip => {

                if (payload[chip.key] !== undefined) {
                    updatedtrashFilterValuesIndividual[chip.key] = payload[chip.key];
                    return { key: chip.key, value: payload[chip.key] };
                }
                return chip;
            });

            return { ...state, trashFilterValues: updatedtrashFilterValuesIndividual, trashFilterChips: updatedFilterChipsIndividual, isUpdate: true };

        case TRASH_FILTER_RESET_INDIVIDUAL_ARR_OBJECTS:

            const resetKey = payload;

            let updatedtrashFilterValuesReset = { ...state.trashFilterValues };
            resetKey?.map((item) => {
                return updatedtrashFilterValuesReset[item] = ''
            })
            return { ...state, trashFilterValues: updatedtrashFilterValuesReset, trashFilterChips: convertObjectToArr(updatedtrashFilterValuesReset), isUpdate: true };
            // if (state.misFilterValues.hasOwnProperty(resetKey)) {
            //     const updatedmisFilterValuesReset = {
            //         ...state.misFilterValues,
            //         [resetKey]: '',
            //     };

            //     const updatedFilterChipsReset = state.misFilterChips.map(chip => {
            //         if (chip.key === resetKey) {
            //             return { key: chip.key, value: '' };
            //         }
            //         return chip;
            //     });
            //     return { ...state, misFilterValues: updatedmisFilterValuesReset, misFilterChips: updatedFilterChipsReset, isUpdate: true };
            // }
            return state;

        case TRASH_FILTER_RESET_ARR_KEYS:
            return { ...state, trashFilterValues: trashFilterValObj, trashFilterChips: convertObjectToArr(trashFilterValObj), isUpdate: true };

        case TRASH_FILTER_IS_UPDATE_RESET:
            return { ...state, isUpdate: false };

        case TRASH_FILTER_INACTION_FAILED:
            return { ...state, error: payload, isUpdate: false };
        case NEW_APP_LAUNCH:
            return { ...initialState }
        // case FILTER_UPDATE_STATE_VAL:
        //     return { ...state, stateValue: payload, isUpdate: true };

        default:
            return state;
    }
};


// const calInital = {
//     events: []
// }
// export const calendarReducer = (state = calInital, action) => {
//     switch (action.type) {
//         case CALENDAR_REQUEST:
//             return { ...state }
//         case CALENDAR_SUCCESS:
//             return {
//                 ...state,
//                 events: action.payload,
//             };
//         case CALENDAR_FAILED:
//             return { ...state };
//         default:
//             return state;
//     }
// };


export default TrashFilterReducer;

