import React, { useState, useEffect } from "react";
function UnderConstruction() {
    const [open, setOpen] = useState(false);
    const [value, setValue] = React.useState(3);
    const [hover, setHover] = React.useState(-1);
    const [closed] = useState(false);

    useEffect(() => {

        const fullUrl = window.location.href;
        const urlObject = new URL(fullUrl);
        const protocol = urlObject.protocol;
        const hostname = urlObject.host;
        const domain = `${protocol}//${hostname}`;
        window.location.href = `${domain}/tendergrid`
    }, []);

    // useEffect(() => {
    //     window.scrollTo(0, 0);
    // }, []);
    return (
        <>
            {/* <section className="constructionWrapper">
                <div className="container">

                    <div className="row align-items-center construction_conatiner">
                        <div className="col-md-12">
                            <div className="text-center">
                                <img src={GGMaintenance} className="Constructiomimg" />
                            </div>
                        </div>

                        <div className="col-md-12 text-center constuction_head">
                            <p>Sorry! we are under Scheduled Maintenance.</p>
                            <span>Our Website is currently under-going scheduled maintenance, will be right back in few mintues.</span><br />
                            <span>Thank you for your patience.</span>
                        </div>

                    </div>
                </div>
            </section> */}
        </>
    );
}

export default UnderConstruction;
