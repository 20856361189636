import { FETCH_USER_INFO_REQUEST, FETCH_USER_INFO_SUCCESS, FETCH_USER_INFO_FAILED, FETCH_USERBID_INFO_REQUEST, FETCH_USERBID_INFO_SUCCESS, FETCH_USERBID_INFO_FAILED, FETCH_USERSTAT_INFO_REQUEST , FETCH_USERSTAT_INFO_SUCCESS, FETCH_USERSTAT_INFO_FAILED} from "../../constants/common/userInfoConstant"

export const userInfoAction = (data) => async (dispatch) => {

    try {
        dispatch({ type: FETCH_USER_INFO_REQUEST })
        dispatch({ type: FETCH_USER_INFO_SUCCESS, payload: data })
    }
    catch (error) {
        dispatch({ type: FETCH_USER_INFO_FAILED, payload: error })
    }
}


export const userBidInfoAction = (data) => async (dispatch) => {

    try {
        dispatch({ type: FETCH_USERBID_INFO_REQUEST })
        dispatch({ type: FETCH_USERBID_INFO_SUCCESS, payload: data })
    }
    catch (error) {
        dispatch({ type: FETCH_USERBID_INFO_FAILED, payload: error })
    }
}


export const userStatInfoAction = (data) => async (dispatch) => {

    try {
        dispatch({ type: FETCH_USERSTAT_INFO_REQUEST })
        dispatch({ type: FETCH_USERSTAT_INFO_SUCCESS, payload: data })
    }
    catch (error) {
        dispatch({ type: FETCH_USERSTAT_INFO_FAILED, payload: error })
    }
}