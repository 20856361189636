// @ts-nocheck
import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { CloseOutlined } from '@ant-design/icons';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useNavigate, useLocation } from "react-router-dom";
import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { UserAuthServices } from '../../../Services/common/Auth/userAuth'
import { UserInfoServices } from '../../../Services/common/user/userinfo'
import { userLoginAction } from '../../../Redux/actions/common/authAction';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { userInfoAction } from "../../../Redux/actions/common/userInfoAction";
import GoogleImg from '../../../assests/img/google.png'
import { COMMON_STRINGS, STRINGS } from "../../../Constants/Strings";
import '../../../common/pages/auth/Login.css'
import ROUTES from "Constants/Routes";

const LoginPopupModal = (props) => {
  const [open, setOpen] = useState(false);
  const location = useLocation();

  const navigate = useNavigate();
  const dispatch = useDispatch()
  const { dynamicMenu } = useSelector((state) => state.dynamicMenuVal)
  const notifySuccess = (msg) => toast.success(msg);
  const notify = (error) => toast.error(error);



  const excludedPaths = [
    '/tendergrid/contact-us',
    '/tendergrid/privacy-policy',
    '/tendergrid/terms-and-conditions',
    '/tendergrid/disclaimer',
    '/bidgrid/',
  ];

  const shouldShowModal = !excludedPaths.some(path => location.pathname.startsWith(path));

  const loginWithGoogle = useGoogleLogin({

    onSuccess: async (tokenResponse) => {
      const userInfo = await axios.get('https://www.googleapis.com/oauth2/v3/userinfo',
        { headers: { Authorization: `Bearer ${tokenResponse.access_token}` } },
      );

      const addSocialLoginDetails = new FormData();
      addSocialLoginDetails.append('emailaddress', userInfo.data.email)

      try {
        UserAuthServices.socialLogin(addSocialLoginDetails).then((res) => {
          if (res?.data?.status === 1) {
            localStorage.setItem('auth', res?.data?.data?.token)
            localStorage.setItem('user_id', res?.data?.data?.user_data?.loginid)
            dispatch(userLoginAction(res.data?.data))
            const objDetiail = new FormData()
            objDetiail.append('user_id', res?.data?.data?.user_data?.loginid)
            try {
              UserInfoServices.requestUser(objDetiail)
                .then((response) => {
                  if (response?.data?.status === 1) {
                    dispatch(userInfoAction(response.data.data))
                    dynamicMenu?.filter(val => val.fld_id === '28')?.map(val => navigate(`/${val.action_url}`))
                    props?.onHide()
                    notifySuccess(STRINGS.LOGIN_SUCCESS_MESSAGE);
                  }
                  else {
                    notify(COMMON_STRINGS.TRY_LATER)
                  }
                })
            }
            catch {
              navigate(ROUTES.SUB_SIGNUP)
            }
          } else {
            notify(COMMON_STRINGS.TRY_AFTER_MIN)
          }
        })
      }
      catch {
        console.log('error')
      }
    },
    onError: errorResponse => console.log(errorResponse),
  });


  const onClose = () => {
    props?.onHide()
  };

  return shouldShowModal && (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body className="p-0">
          <div className="Get-Quote">
            <div className="box">
              <div className="body model_login_popup">
                <Row>
                  <Col md={12}>
                    <div className="close" onClick={onClose}>
                      <CloseOutlined />
                    </div>
                    <div className="text-center">
                      <div className="about-content-two">
                        <h1 className="mt-0">
                          <span className="one-text"></span>
                        </h1>
                      </div>
                      <p>Login To View The Details!</p>
                    </div>

                    <div className="formWarpper">
                      <div className="section-title text-center">
                      </div>
                      <form className="form" method="" action="">
                        <div className="form__icons">
                          <a href="javascript:void(0)"
                            className="socailLogin"
                            onClick={() => loginWithGoogle()}
                          >
                            <img src={GoogleImg} alt="" /> Continue with
                            Google
                          </a>
                          {/* <FacebookLogin
                            appId="1088597931155576"
                            autoLoad={false}
                            cssClass="socailfacebook"
                            fields="name,email,picture"
                            scope="public_profile,user_friends"
                            // callback={responseFacebook}
                            icon="fa-facebook"
                            textButton="Continue with Facebook"
                          /> */}
                        </div>
                        <div className="orItem">
                          <div className="signUpLink">Already have an account ?
                            <a href='javascript:void(0);'
                              onClick={() => {
                                dynamicMenu?.filter(val => val?.fld_id === '24')?.map(item => navigate(`/${item?.action_url}`))
                                props?.onHide()
                              }}
                            > Login</a></div>
                          <div className="iier">or</div>
                        </div>
                        <div className="signUpLink">Don't have an account ?
                          <a
                            href="javascript:void(0);"
                            onClick={() => {
                              dynamicMenu?.filter(val => val?.fld_id === '25')?.map(item => navigate(`/${item?.action_url}`))
                              props?.onHide()
                            }}
                          > Sign Up
                          </a></div>
                      </form>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default LoginPopupModal;
