// @ts-nocheck
import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import LoginPopupModal from 'Tendergrids/components/modal/LoginPopupModal';
import DropdownValues from './Includes/DropdownValues';
import { useEffect, useState } from 'react';
import ROUTES from "Constants/Routes"
import Error404 from 'common/pages/Error404/Error404';
import { useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { NavBarMenu } from 'Services/common/navbar/NavBarMenu';
import { useDispatch } from 'react-redux';
import UnderConstruction from 'UnderConstruction/UnderConstruction';
import { dynamicMenuAction } from 'Redux/actions/common/DropdownAction';
import { appVersionChangeAction } from 'Redux/actions/common/authAction';

const CommonApp = lazy(() => import('common/CommonApp'));
const TenderGridApp = lazy(() => import('Tendergrids/TenderGridApp'));
const StatgridApp = lazy(() => import('Statgrid/StatgridApp'));
const BidGridApp = lazy(() => import('BidGrid/BidGridApp'));
const CompanyVisitor = lazy(() => import('companyVisitor'));

const EmptyComponent = () => null;
const AppVersion = '1.01.09'
function App() {
  const [width, setWidth] = React.useState(window.innerWidth);
  const [openLogin, setOpenLogin] = useState(false)
  const [openBidLogin, setOpenBidLogin] = useState(false)
  const { dynamicMenu } = useSelector((state) => state.dynamicMenuVal)
  const { userData } = useSelector((state) => state.loginData)
  const currentDate = new Date();
  const { bidgridData } = useSelector((state) => state.loginData)
  const { statgridData } = useSelector((state) => state.loginData)
  const currentYear = currentDate.getFullYear();
  const startYear = currentYear - (currentDate.getMonth() < 3 ? 1 : 0);
  const endYear = startYear + 1;
  const isInitialized = localStorage.getItem('isInitialized');

  const financialYear = `${startYear}-${endYear}`;
  localStorage.setItem('fin_year', financialYear)
  const path = window.location.pathname;
  const dispatch = useDispatch()


  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);





  const excludedPaths = [ROUTES.TENDER_GRID, ROUTES.LOGIN, ROUTES.ROOT, ROUTES.SIGNUP, ROUTES.FORGOT_PASSWORD, ROUTES.ERROR, ROUTES.BD_LOGIN, ROUTES?.BLOG, ROUTES?.BLOG_DETAIL];
  useEffect(() => {
    const inputString = window.location.pathname;
    console.log(inputString, "input stringgggg");
    const openModalIfNeeded = () => {
      const shouldOpenLogin = !userData?.user_data?.loginid &&
        !excludedPaths.includes(inputString) &&

        !inputString.includes("/bidgrid") &&
        !inputString.includes("/statgrid");

      if (shouldOpenLogin) {
        setOpenLogin(true);
      }
    };

    openModalIfNeeded();
    const intervalId = setInterval(openModalIfNeeded, 2000);
    return () => {
      clearInterval(intervalId);
    };
  }, [userData, bidgridData, statgridData]);

  // useEffect(() => {
  //   const inputString = window.location.pathname;
  //   const excludedPaths = [ROUTES.LOGIN, ROUTES.ROOT, ROUTES.SIGNUP, ROUTES.FORGOT_PASSWORD, ROUTES.ERROR, ROUTES.BD_LOGIN];

  //   const openModalIfNeeded = () => {
  //     const shouldOpenLogin = !userData?.user_data?.loginid &&
  //       !excludedPaths.includes(inputString) &&
  //       !inputString.includes("/bidgrid") &&
  //       !inputString.includes("/statgrid");

  //     if (shouldOpenLogin) {
  //       setOpenLogin(true);
  //     }
  //   };

  //   openModalIfNeeded();
  //   const intervalId = setInterval(openModalIfNeeded, 60000);
  //   return () => {
  //     clearInterval(intervalId);
  //   };
  // }, [userData, bidgridData, statgridData]);


  const getNavBarMenuItem = () => {
    try {
      const loginObj = dynamicMenu?.find(val => val.menu_name === 'login')
      if (loginObj?.action_url !== 'tendergrid/login') {
        NavBarMenu.getNavBar().then((response) => {
          let data = Object?.values(response?.data?.data)
          dispatch(dynamicMenuAction(data))

        });
      } else {
        return
      }

    } catch (error) {
      console.log(error, "error");
    }
  };

  // const sessionFunction = () => {
  //   debugger;
  //   const sessionVal = sessionStorage.getItem('tenderUrl')
  //   if (sessionVal === '') {
  //     sessionStorage.setItem('tenderUrl', '')
  //     return
  //   } else {
  //     return
  //   }
  // }

  // useEffect(() => {
  //   sessionFunction()
  // }, [sessionStorage.getItem('tenderUrl')])

  useEffect(() => {
    getNavBarMenuItem()

  }, [])

  useEffect(() => {
    if (localStorage.getItem('version') !== AppVersion || localStorage.getItem('version') === null) {
      dispatch(appVersionChangeAction())
      localStorage.setItem('version', AppVersion)
    }
  }, [])

  return (
    <>
      <ToastContainer position="bottom-center" autoClose={1500} />

      <Router>

        <DropdownValues />
        <Routes>
          <Route path='/' element={<UnderConstruction />} />
          <Route exact path="/tendergrid/*" element={<Suspense fallback={<div></div>}><TenderGridApp /></Suspense>} />
          <Route path='/tendergrid/sitemap.xml' element={<EmptyComponent />} />
          <Route path='/tendergrid/robots.txt' element={<EmptyComponent />} />
          <Route path="/apps/*" element={<Suspense fallback={<div></div>}><CommonApp /></Suspense>} />
          <Route path="/statgrid/*" element={<Suspense fallback={<div></div>}><StatgridApp /></Suspense>} />
          <Route path="/bidgrid/*" element={<Suspense fallback={<div></div>}><BidGridApp /></Suspense>} />
          <Route path="/company-visitor/*" element={<Suspense fallback={<div></div>}><CompanyVisitor /></Suspense>} />
          <Route path='/error' element={<Error404 />} />
          <Route path="*" element={<Navigate to="/error" replace />} />
        </Routes>
        {/* <LoginPopupModal show={openLogin} onHide={() => setOpenLogin(false)} /> */}

        {!excludedPaths.includes(window.location.pathname) || !window.location.pathname.includes(ROUTES?.BLOG_DETAIL) && (
          <LoginPopupModal show={openLogin} onHide={() => setOpenLogin(false)} />
        )}
      </Router>
    </>
  );
}


export default App;
