import { STAT_CHECKOUT_INFO_FAILURE, STAT_CHECKOUT_INFO_REQUEST, STAT_CHECKOUT_INFO_SUCCESS } from "Redux/constants/statgrid/statCheckoutConstant"


const initialState = {
 
    statSubPlan: {},
    type: 'wallet'
    
}

export const StatCheckoutReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case STAT_CHECKOUT_INFO_REQUEST:
            return { ...state }
        case STAT_CHECKOUT_INFO_SUCCESS:
            return {
                ...state,
              
                statSubPlan: payload.statSubPlan,
                type: payload.type
                
            }

        case STAT_CHECKOUT_INFO_FAILURE:
            return { ...state, error: payload }
        default: return state
    }
}