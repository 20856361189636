export const STAT_FILTER_INACTION_REQUEST = 'STAT_FILTER_INACTION_REQUEST'

export const STAT_FILTER_UPDATE_ALL_KEYS_OBJECTS = 'STAT_FILTER_UPDATE_ALL_KEYS_OBJECTS'

export const STAT_FILTER_UPDATE_INDIVIDUAL_OBJECTS_KEYS = 'STAT_FILTER_UPDATE_INDIVIDUAL_OBJECTS_KEYS'

export const STAT_FILTER_RESET_ARR_KEYS = 'STAT_FILTER_RESET_ARR_KEYS'

export const STAT_FILTER_RESET_INDIVIDUAL_ARR_OBJECTS = 'STAT_FILTER_RESET_INDIVIDUAL_ARR_OBJECTS'

export const STAT_FILTER_IS_UPDATE_RESET = 'STAT_FILTER_IS_UPDATE_RESET'
export const STAT_FILTER_IS_UPDATE_RESET_TRUE = 'STAT_FILTER_IS_UPDATE_RESET_TRUE'

export const STAT_FILTER_INACTION_FAILED = 'STAT_FILTER_INACTION_FAILED'

export const STAT_FILTER_UPDATE_STATE_VAL = 'STAT_FILTER_UPDATE_STATE_VAL'