// @ts-nocheck
const ROUTES = {

    // Common Routes

    LOGIN: "/tendergrid/login",
    BD_LOGIN: "/bidgrid/login",
    SIGNUP: "/tendergrid/signUp",
    PROFILE: "/tendergrid/profile",
    TENDER_GRID: "/tendergrid",
    FORGOT_PASSWORD: "/tendergrid/forgot-password",
    WALLET: "/tendergrid/my-wallet",
    CHECKOUT: '/apps/checkout',
    CONDITION: 'apps/conditions',

    //TenderGrid Routes

    TENDER_DETAILS: "/tendergrid/tender-details/:id",
    CONTACT: "/tendergrid/contact-us",
    ABOUT_US: "/tendergrid/aboutus",
    BLOG: "/tendergrid/blogs",
    FAQS: '/tendergrid/faqs',
    BLOG_DETAIL: '/tendergrid/blog/:title',
    CLIENT: "/tendergrid/client",
    TENDER_LIST: "/tendergrid/tender-list",
    TENDER_LIST_ID: "/tendergrid/tender-list/:type/:id",
    TENDER_LIST_TYPE: "/tendergrid/tender-list/:type/:id",
    MY_FAVOURITE: "/tendergrid/my-favourite",
    LAST_HOUR: "/tendergrid/last-24-hours",
    ARCHIVE: "/tendergrid/archive",
    RECOMMENDED: "/tendergrid/recommended",
    SUBSCRIBE_ARCHIVE: "/tendergrid/subscribe-archive",
    SUBSCRIBE_LIVE: "/tendergrid/subscribe-live",
    TENDER_BOUGHT: "/tendergrid/purchased-tenders",
    CATEGORY_LIST: "/tendergrid/categorieslist",
    CITY_WISE_TENDER: "/tendergrid/citywisetender",
    ORGANIZATION: "/tendergrid/authority-wise-tenders",
    SUBSCRIBE: "/tendergrid/plans",
    STATE_WISE_TENDER: "/tendergrid/state-wise-tenders",
    CATEGORY_WISE_TENDER: "/tendergrid/sector-wise-tenders",
    SETTING: "/tendergrid/setting",
    MY_WISHLIST: "/tendergrid/mywishlist",
    FEEDBACK: "/tendergrid/feedback",
    SUPPORT: "/tendergrid/support",
    WEBSITE: "/tendergrid/website",
    TENDER_Notice: "/tendergrid/tender-notice",
    UPDATE_TENDER_LIST: "/tendergrid/updated-tenders",

    GEM_TENDER: "/tendergrid/gem-tenders",
    GEM_LAST_HOUR: "/tendergrid/last-24-hours-gem-tenders",
    GEM_ARCHIVE: "/tendergrid/archive-gem-tenders",
    GEM_FAVIROT: "/tendergrid/gem-favourite-tenders",


    TENDER_ARCHIVE: "/tendergrid/archive-tender-notice",

    FAV_NOTICE: "/tendergrid/favourite-notice",


    IREPS_TENDER: "/tendergrid/ireps-tenders",
    IREPS_LAST_HOUR: "/tendergrid/last-24-hours-ireps-tenders",
    IREPS_ARCHIVE: "/tendergrid/archive-ireps-tenders",
    IREPS_FAVIROT: "/tendergrid/ireps-favourite-tenders",

    EPROC_TENDER: "/tendergrid/eproc-tenders",
    EPROC_ARCHIVE: "/tendergrid/archive-eproc-tenders",
    EPROC_LAST_HOUR: "/tendergrid/last-24-hours-eproc-tenders",
    EPROC_FAVIROT: "/tendergrid/eproc-favourite-tenders",

    PMGSY_TENDER: "/tendergrid/pmgsy-tenders",
    PMGSY_ARCHIVE: "/tendergrid/archive-pmgsy-tenders",
    PMGSY_LAST_HOUR: "/tendergrid/last-24-hours-pmgsy-tenders",
    PMGSY_FAVIROT: "/tendergrid/pmgsy-favourite-tenders",


    EMPLOYEELIST: "/tendergrid/employee-list",


    UPDATE_PMGSY: "/tendergrid/update-pmgsy-tenders",

    SITEMAP: "/tendergrid/sitemap",
    //................

    // statGrid Routes

    STAT_LOGIN: "/statgrid/login",
    STAT_SIGNUP: "/statgrid/signup",
    STAT_FORGOT_PASSWORD: "/statgrid/forgot-password",
    // main routs 
    STATDASHBOARD: "/statgrid/dashboard",

    COMPANIES: "/statgrid/companies",
    COMPANY_DETAIL: "/statgrid/company",


    PERFORMANCE: "/statgrid/performance",
    PERFORMANCE_DETAIL: "/statgrid/performance",

    RESULTS: "/statgrid/results",
    RESULT_DETAIL: "/statgrid/result",

    MARKET_SHARE: "/statgrid/market-share",

    TENDER_CALENDER: "/statgrid/tender-calender",
    REMINDER: "/statgrid/reminder",
    SAVED_SEARCH: "/statgrid/saved-search",

    // ABOUT_US : "/statgrid/about-us",

    // COMPANIES: "/statgrid/companies",
    // COMPANY_DETAILS: "/statgrid/company/:id",
    // RESULT_LIST: "/statgrid/result-list",
    // RESULT_DETAILS: "/statgrid/result/:id",
    // STATE_GRID_ROOT: "/statgrid/",
    // STATS: "/statgrid/stats",
    // STATGRID_DASHBOARD: "/statgrid",
    // STATGRID_CALENDER: "/statgrid/statgrid-calender",
    // COMPANY_LIST: "/statgrid/companylist",
    // // STATGRID_HOME:"/statgrid/statgridhome",
    // STATGRID_REMINDER: "/statgrid/reminder",
    // STATGRID_SAVETENDER: "/statgrid/savetender",
    // // STATGRID_HOME:"/statgrid/statgridhome",
    // MARKET_SHARE:"/statgrid/market-share",
    // MARKET_SHARE_DETAILS:"/statgrid/marketsharedetails",
    // STATGRID_HOME:"/statgrid/statgridhome",
    // STAT_TENDER_RESULT: "/statgrid/tenderresult",

    // COMPANY_DASHBOARD: "/statgrid/companydashboard",
    // RESULT_DETAILS_PAGE: "/statgrid/resultdetails",
    // PERFORMANCE: "/statgrid/performance",


    //....................

    //BidGrid Routes
    PREPAREFACTSHEET: "/bidgrid/PrepareFactSheet",
    MEETING: "/bidgrid/meetingschedules",
    DASHBOARD: "/bidgrid/dashboard",
    TASK_MANAGER: "/bidgrid/taskmanager",
    Task_assigine: "/bidgrid/taskassigine",
    MODELS: "/bidgrid/model",
    TeamRequisitionDesignation: "/bidgrid/team-rq-designation",
    TeamRequisitionDesignationCategory: "/bidgrid/team-rq-category",
    REQUEST: "/bidgrid/request",
    BANKGUARANTEE: "/bidgrid/bankguarantee",
    TENDER_COMPANY: "/bidgrid/AddTender/tender-company",
    COMPANY: "/bidgrid/AddTender/company",
    ToDo: "/bidgrid/todo",
    WISHLIST: "/bidgrid/wishlist",
    WORKLOAD_DISTRIBUTION: "/bidgrid/workdistribution",
    CALENDAR: "/bidgrid/calendar",
    LIVE_TENDERS: "/bidgrid/livetender",
    Personal_reminder: "/bidgrid/reminder",
    BD_PROFILE: "/bidgrid/profile",
    BD_EMP_PROFILE: "/bidgrid/profile/:id",
    New_Tenders: "/bidgrid/newtender",
    New_assigne: "/bidgrid/newassigne",
    Trash: "/bidgrid/trash",
    document_share: "/bidgrid/document",
    PROSPECTIVE_TENDER: "/bidgrid/prospectivetender",
    CHATBOAT: "/bidgrid/chatboat",
    BD_TENDERDETAILS: "/bidgrid/bdtenderdetails/:id",
    ConFiguration: '/bidgrid/Configuration/configuration',
    EMPLOYEELIST: '/bidgrid/employeelist',
    BRIEFCASE: '/bidgrid/briefcase',
    BDREQUEST: "/bidgrid/pages/bdtenderdetails",
    MIS: "/bidgrid/mis",

    BD_CONFIG: "/bidgrid/config",

    //masters
    ADDUNIT: "/bidgrid/master/business-unit",
    JOBTITLE: "/bidgrid/master/jobtitle",
    PREFIX: "/bidgrid/master/prefix",
    PREFIXNO: "/bidgrid/master/prefixNo",
    ROLE: "/bidgrid/master/role",
    DESIGNATION: "/bidgrid/master/designation",
    ROLEPERMISSIONS: '/bidgrid/master/role-permissions',
    WIDGETSACTION: '/bidgrid/master/widgetaction',
    TENDERCYCLEACTION: '/bidgrid/master/tendercycleaction',
    CONFIGURATIONLEVEL: '/bidgrid/master/configurationlevel',

    CURRENCY: "/bidgrid/master/currency",
    LANGUAGE: "/bidgrid/master/language",
    TIMEZONE: "/bidgrid/master/timezone",
    ASSIGNSECTOR: "/bidgrid/master/assignsector",
    KEYWORD: "/bidgrid/master/keyword",
    LOGO: "/bidgrid/master/Logo",
    SUBSECTOR: "/bidgrid/master/subsector",
    TENDER_STATUS: "/bidgrid/master/tenderstatus",
    DOCTROL: "/bidgrid/master/doctrol",
    BANK: "/bidgrid/master/bank",
    BD_CLIENT: "/bidgrid/master/client",
    CONSORTIUM: "/bidgrid/master/consortium",
    NATIONALITY: "/bidgrid/master/nationality",
    GENDER: '/bidgrid/master/gender',
    DEPARTMENT_MANAGE: "/bidgrid/master/department",
    TENDER_PHASE: "/bidgrid/master/phase",
    TENDER_SCOPE: "/bidgrid/master/tenderscope",
    FUNDING_AGENCY: "/bidgrid/master/fundingagency",
    COUNTRYLIST: "/bidgrid/master/countrylist",
    STATELIST: "/bidgrid/master/statelist",
    CITYLIST: "/bidgrid/master/citylist",
    CONTINENTLIST: "/bidgrid/master/continentlist",
    REGIONLIST: "/bidgrid/master/regionlist",
    EMPLOYEE: "/bidgrid/master/employee",
    EMPLOYEE_STATUS: "/bidgrid/master/employeeStatus",
    BLOODGROUP: "/bidgrid/master/bloodgroup",
    TENDER_SERVICES: "/bidgrid/master/tenderservies",





    // BD_TENDERDETAILS_id: "/bidgrid/bdtenderdetails/:id",

    //................

    // COMPANY_LIST: "/companylist",
    STATSGRID_INFO: "/apps/statgridinfo",
    DISCLAIMER: "/tendergrid/disclaimer",
    TERMS_POLICY: "/tendergrid/privacy-policy",
    CONDITIONS: "/tendergrid/terms-and-conditions",
    SUB_SIGNUP: "/subdivision/signup",
    USERLIST: "/statgrid/userlist",
    ADDUSER: "/statgrid/adduser",
    PROFILES: "/statgrid/profile",

    // ERROR: "/error",
    ROOT: "/",
    WILDCARD: "*"
}

export default ROUTES