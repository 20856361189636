import { BID_DYNAMIC_MENU_FAILED, BID_DYNAMIC_MENU_REQUEST, BID_DYNAMIC_MENU_SUCCESS, NAVIGATION_DATA } from "Redux/constants/bidgrid/bidgridConstants";
import { NEW_APP_LAUNCH } from "Redux/constants/common/authConstant";
const initialState = {
    navigateData: {}
};

const navigationDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case NAVIGATION_DATA:
            return {
                ...state,
                navigateData: action.payload,
            };
        case NEW_APP_LAUNCH:
            return { ...initialState }
        default:
            return state;
    }
};


export const BidMenuReducer = (state = { bidMenu: [], }, { type, payload }) => {
    switch (type) {
        case BID_DYNAMIC_MENU_REQUEST:
            return { ...state }
        case BID_DYNAMIC_MENU_SUCCESS:
            return { ...state, bidMenu: payload }
        case BID_DYNAMIC_MENU_FAILED:
            return { ...state, error: payload }
        case NEW_APP_LAUNCH:
            return { bidMenu: [] }
        default: return state
    }
}

export default navigationDataReducer;