import { BLOG_INACTION_FAILED, BLOG_INACTION_REQUEST, BLOG_UPDATE_ALL_KEYS_OBJECTS } from "Redux/constants/tendergrid/BlogConstant";
import { NEW_APP_LAUNCH } from "Redux/constants/common/authConstant";

let blogValObj = {
    limit: 25,
    Page_number: 1,
    category_id: "",
}

export const initialState = {
    blogValues: blogValObj,
    isUpdate: false,
    error: ''
};

const BlogReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case BLOG_INACTION_REQUEST:
            return { ...state }
        case BLOG_UPDATE_ALL_KEYS_OBJECTS:
            const blogUpdateValues = { ...payload };
            return { ...state, blogValues: blogUpdateValues, isUpdate: true }
        case BLOG_INACTION_FAILED:
            return { ...state, error: payload, isUpdate: false };
        case NEW_APP_LAUNCH:
            return { ...initialState }
        default:
            return state;

    }

}

export default BlogReducer