import { PERSIST_REQUEST, PERSIST_SUCCESS, PERSIST_FAILED } from "../../constants/common/persistConstant"

const initialState = {
    persistDatas: {},
}
export const empersistReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case PERSIST_REQUEST:
            return { ...state }
        case PERSIST_SUCCESS:
            return { ...state, persistDatas: payload }
        case PERSIST_FAILED:
            return { ...state, error: payload }
        default: return state
    }
}