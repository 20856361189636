export const DROPDOWN_REQUEST = "DROPDOWN_REQUEST"
export const DROPDOWN_SUCCESS = "DROPDOWN_SUCCESS"
export const DROPDOWN_FAILED = "DROPDOWN_FAILED"
export const DROPDOWN_RESETALL = "DROPDOWN_RESETALL"

export const SEO_REQUEST = "SEO_REQUEST"
export const SEO_SUCCESS = "SEO_SUCCESS"
export const SEO_FAILED = "SEO_FAILED"

export const DYNAMIC_MENU_REQUEST = "DYNAMIC_MENU_REQUEST"
export const DYNAMIC_MENU_SUCCESS = "DYNAMIC_MENU_SUCCESS"
export const DYNAMIC_MENU_FAILED = "DYNAMIC_MENU_FAILED"

export const STAT_DROPDOWN_REQUEST = "STAT_DROPDOWN_REQUEST"
export const STAT_CLIENT_SUCCESS = "STAT_CLIENT_SUCCESS"
export const STAT_FUNDING_SUCCESS = "STAT_FUNDING_SUCCESS"
export const STAT_TENDER_CAT_SUCCESS = "STAT_TENDER_CAT_SUCCESS"
export const STAT_TENDER_COUNTRY_SUCCESS = "STAT_TENDER_COUNTRY_SUCCESS"
export const STAT_TENDER_STATE_SUCCESS = "STAT_TENDER_STATE_SUCCESS"
export const STAT_SECTOR_SUCCESS = "STAT_SECTOR_SUCCESS"

export const STAT_DROPDOWN_FAILED = "STAT_DROPDOWN_FAILED"

export const STAT_DROPDOWN_RESETALL = "STAT_DROPDOWN_RESETALL"


export const BID_DROPDOWN_REQUEST = " BID_DROPDOWN_REQUEST"
export const BID_CLIENT_SUCCESS = "BID_CLIENT_SUCCESS"


export const BID_CLIENT_FUNDING_AGENCY_SUCCESS = "BID_CLIENT_FUNDING_AGENCY_SUCCESS"
export const BID_COUNTRY_SUCCESS = "BID_COUNTRY_SUCCESS"
export const BID_CURRENCY_SUCCESS = "BID_CURRENCY_SUCCESS"
export const BID_SECTOR_SUCCESS = "BID_SECTOR_SUCCESS"

export const BID_DROPDOWN_RESETALL = "BID_DROPDOWN_RESETALL"
export const DROPDOWN_MENU_RESET = "DROPDOWN_MENU_RESET"

export const BID_DROPDOWN_FAILED = "BID_DROPDOWN_FAILED"
