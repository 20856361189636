import {
    COMPANY_BALANCE_REQUEST , COMPANY_BALANCE_SUCCESS,COMAPNY_BALANCE_FAILED
} from "../../constants/statgrid/statComapnyBalanceConstatnt"

const initialState = {
    companyBalance: [],
}

export const companybalanceReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case COMPANY_BALANCE_REQUEST:
            return { ...state }
        case COMPANY_BALANCE_SUCCESS:
            return { ...state, companyBalance: payload }
        case COMAPNY_BALANCE_FAILED:
            return { ...state, error: payload }
        default: return state
    }
}
