export const STRINGS = {
    LOGIN_SUCCESS_MESSAGE: "Login sucessfull!",
    DEFAULT_COLLECTION_VALUE: "DEFAULT_CONSTANT_ALL",
    OUR_PRODUCT: 'Our Products',
    SIGNUP_FAILED_MESSAGE: "Please enter a correct valid number.",
    Tender_value: "estm_value",
    FREE_TRAIL_SUBMIT_SUCCESS: "We will get back to you shortly!",
    SIGNUP_SUCCESS: "Thank you for choosing Tender Grids!"
}

export const COMMON_STRINGS = {
    REGISTER_EMAIL: "Please enter your registered Email",
    ALREADY_EMAIL: "Email already exists.",
    CORRECT_EMAIL: "Please enter a valid Email address.",
    EMPTY_ALL_FIELDS: "Please fill out all the required fields.",
    EMPTY_EMAIL: "Please enter your Email.",
    EMPTY_NAME: "Please enter your Full Name.",
    EMPTY_PASSWORD: 'Please enter the Password.',
    INVALID_USER_PASSWORD: "Invalid Username / Invalid Password.",
    TRY_LATER: 'Please try again later!',
    INVALID_CREDENTIAL: "Invalid credentials.",
    TRY_AFTER_MIN: ' Oops! Try again after a few minutes.',
    IF_SIMILAR_PASSWORD: " Password should not be the same as the first name, last name, email and mobile number.",
    REQUIRED_LOWER_LETTER_PASSWORD: "Password should contain a lowercase letter.",
    REQUIRED_UPPER_LETTER_PASSWORD: "Password should contain an uppercase letter.",
    REQUIRED_NUMBER_PASSWORD: "Password must include numeric characters.",
    REQUIRED_SPECIAL_PASSWORD: "Password must include special characters.",
    REQUIRED_LENGTH_PASSWORD: "Password length should be min 8 and max 16 characters.",
    REQUIRED_STRONG_PASSWORD: " Password is strong.",
    REQUIRED_NUMBER: "Enter your 10 digit mobile number.",
    REQUIRED_10_DIGIT_NUMBER: "Please enter a valid 10-digit Mobile Number.",
    INVALID_NUMBER: '  Invalid Number.',
    INVALID_DATE: "Invalid Date.",
    NO_AVAILABLE: "Rows not available.",
    REQUIRED_MOBILE_NUMBER: "Mobile Number is required.",
    VALID_EMAIL: 'Please enter a valid Email Address.',
    ENTER_MOBILE_NUMBER: " Please enter your Mobile Number.",
    SELECT_SECTORS: 'Please select sectors.',
    SELECT_AUTHORITIES: 'Please select  authorities.',
    SELECT_STATE: 'Please select states.',
    PLAN_ACTIVE: "Congratulations! Your plan is activated.",
    NAME_MANDATORY: 'Entering first name is mandatory.',
    PROFILE_UPDATE_SUCCESSFULLY: "User profile successfully updated.",
    ENTER_OTP: 'Please enter OTP.',
    OTP_VERIFY: "Please enter the OTP to verify your account.",
    CORRECT_OTP: 'Please enter accurate OTP!',
    PLAN_UPDATE: "Your plan is updated!",
    WRONG_FILE: "Incorrect file format.",
    FILL_QUERY: "Please fill out your query!",
    REQUIRED_KEYWORD: "Entering  keyword is mandatory!",
    REQUIRED_MESSAGE: "Entering message is mandatory.",
    REQUIRED_SUBJECT: "Entering subject is mandatory.",
    REMOVE_FAVORITE: 'Successfully removed from favourites!',
    ENTER_PASSWORD_CONFIRMPASSWORD: 'Enter a new password and confirm the password.',
    ENTER_CONFIRMPASSWORD: 'Please enter the confirmed password.',
    ENTER_NEW_PASSWORD: 'Please enter the new password.',
    ENTER_OLD_PASSWORD: 'Please enter the old  password.',
    OLD_NEW_PSW_NOT_SAME: "New password shouldn't be the same as the previous one.",
    PASSWORD_MISMATCH: ' Password did not match.',
    PASSWORD_CHANGE_SUCCESS: "Password changed successfully,please login again.",
    USER_ADD_SUCCESS: " User successfully added.",
    LOGIN_FIRST: 'Please login first.',
    FEEDBACK_REQUIRED: "Feedback message is required.",
    RATING_REQUIRED: "Rating is compulsory.",
    COMPANY_NAME: "Please Enter Your Company Name.",
}


export const BILLING_STRINGS = {
    COUPON_DURATION: "Your  coupon is applied.",
    COUPON_APPLIED: 'Your coupon is applied.',
    COUPON_NOT_APPLIED: 'This Coupon is not applicable here.',
    INCREASE_COUPON_APPLIES: 'Your coupon is applied.',
    PLAN_RENEWED: "Congratulations! Your plan has been renewed!",
    TRANSACTION_SUCCESS: "Transaction was successfully initiated, and points are deducted from the wallet.",
    AMOUNT_DEDUCTED: "If the amount is deducted, raise a ticket! We will get back to you shortly.",
    ENTER_POINT_ADD: 'Please enter the points you wish to add.',

}


export const TENDERGRID_STRINGS = {
    FAVOURITE_COLLECTION_CREATE: "New favourite collection is created.",
    COLLECTION_ALREADY_EXIST: "Collection name already exists.",
    COLLECTION_NAME: "Enter collection name.",
    FAVORITE_ADD_SUCCESS: "Tender sucessfully added to favourite list. ",
    SELECT_ONE_COLLECTION: 'Please select one collection.',
    COLLECTION_DELETE: "Collection deleted.",
    COLLECTION_NAME_UPDATE: " Collection name successfully updated.",
    TICKET_DELETE: "Ticket deleted sucessfully!",
    ALREADY_DELETE: "Rows already deleted.",
    ALREADY_TICKET: "Ticket already raised!",

    THREE_FIELDS_AVAILABLE: 'Only three input fields are available.',
    CANT_EDIT_SUBMIT: "You can't edit the information, once it's submitted.",

    PURCHASE_TENDER: 'You need to purchase a plan in order to access tenders.',
    CONFIGURE_PLAN: 'You need to configure the plan. Go to settings and update the configuration! ',
    NO_RECORD: 'No record found.',
}


export const REMARKS_STRINGS = {
    KEY_CLIENT_MANAGER: 'You have been assigned as a key client manager for a tender.',
    BID_MANAGER: 'You have been assigned as a bid manager for a tender.',

    MENTION_TODO: 'You have been mentioned in a task.',
    TODO_CREATE_TASK: 'You have been assigned a new task.',
    TODO_UPDATE_TASK: 'Your assigned task has been updated.',

    TODO_SCOPE_CHANGE_TODO_TO_PROGRESS: 'The scope of your task has changed from todo to in-progress.',
    TODO_SCOPE_CHANGE_TODO_TO_DONE: 'The scope of your task has changed from todo to done.',
    TODO_SCOPE_CHANGE_PROGRESS_TO_DONE: 'The scope of your task has changed from in-progress to done.',
    TODO_SCOPE_CHANGE_PROGRESS_TO_TODO: 'The scope of your task has changed from in-progress to todo.',
    TODO_SCOPE_CHANGE_DONE_TO_TODO: 'The scope of your task has changed from done to todo.',
    TODO_SCOPE_CHANGE_DONE_TO_PROGRESS: 'The scope of your task has changed from done to in-progress.',


    TODO_PROJECT_CREATE_TASK: 'You have been assigned a new project task.',
    TODO_PROJECT_UPDATE_TASK: 'Your assigned project task has been updated.',
    MENTION_TODO_PROJECT: 'You have been mentioned in a project task.',

    TODO_PROJECT_SCOPE_CHANGE_TODO_TO_PROGRESS: 'The scope of your project task has changed from todo to in-progress.',
    TODO_PROJECT_SCOPE_CHANGE_TODO_TO_DONE: 'The scope of your project task has changed from todo to done.',
    TODO_PROJECT_SCOPE_CHANGE_PROGRESS_TO_DONE: 'The scope of your project task has changed from in-progress to done.',
    TODO_PROJECT_SCOPE_CHANGE_PROGRESS_TO_TODO: 'The scope of your project task has changed from in-progress to todo.',
    TODO_PROJECT_SCOPE_CHANGE_DONE_TO_TODO: 'The scope of your project task has changed from done to todo.',
    TODO_PROJECT_SCOPE_CHANGE_DONE_TO_PROGRESS: 'The scope of your project task has changed from done to in-progress.',

    MENTION_TENDER: 'You have been mentioned in a tender.',

    USER_PROFILE_UPDATE: 'Your profile has been updated.',

    REQUEST_CREATE: 'You have been requested for a tender.',
    REQUEST_UPDATE: 'There has been an update to a request.',
    REQUEST_STATUS_PENDING_TO_SUBMITTED: 'The request status has changed from pending to submitted.',
    REQUEST_STATUS_SUBMITTED_TO_APPROVED: 'The request status has changed from submitted to approved.',
    REQUEST_STATUS_PENDING_TO_APPROVED: 'The request status has changed from pending to approved.',
    REQUEST_STATUS_SUBMITTED_TO_PENDING: 'The request status has changed from submitted to pending.',

    MEETING_CREATE: 'You have been invited to a meeting.',
    MEETING_UPDATE: 'There are updates to the meeting.',

    TENDER_SCOPE_CHANGE: 'The scope of the tender has changed.',
}